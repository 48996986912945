import { FEATURE } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import OnboardingPageHeader from 'src/components/Onboarding/OnboardingPageHeader'
import OnboardingSettings from 'src/components/Onboarding/OnboardingSettings'
import useFeature from 'src/lib/hooks/useFeature'

const OnboardingClientSettingsPage = () => {
  // Get Feature Flag Status
  const onboardingFeatureFlag = useFeature(FEATURE.ONBOARDING)

  // Navigate away when permission denied
  if (!onboardingFeatureFlag) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata
        title="Setup - Client Settings"
        description="Setup - Client Settings page"
      />
      <div className="relative">
        <OnboardingPageHeader
          currentPage={{
            name: 'Client Settings',
            url: routes.onboardingClientSettings(),
          }}
        />
        <OnboardingSettings />
      </div>
    </>
  )
}

export default OnboardingClientSettingsPage
